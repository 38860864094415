
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { BaseButton },
})
export default class Hero extends Vue {
  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
