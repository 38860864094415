
import Hero from '@/components/creditpushMain/HomePage/Hero.vue';
import SolutionsSlide from '@/components/creditpushMain/HomePage/SolutionsSlide.vue';
import { Component, Vue } from 'vue-property-decorator';

const HowItWorks = () => import('@/components/creditpushMain/HomePage/HowItWorks.vue');
const Integrations = () => import('@/components/creditpushMain/HomePage/Integrations.vue');
const Blog = () => import('@/components/creditpushMain/HomePage/Blog.vue');

@Component({
  components: {
    Hero,
    SolutionsSlide,
    HowItWorks,
    Integrations,
    Blog,
  },
})
export default class HomePage extends Vue {
  mounted(): void {
    this.$store.commit('flowStages/resetFlowVersion');
  }
}
