
import { Component, Vue } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue';

@Component({
  components: {
    BaseButton,
    IconArrow,
    IconBreadcrumb,
  },
})
export default class SolutionsSlide extends Vue {
  currentSlide = 0;
  currentAccordion = NaN;

  cards = [
    {
      title: 'Bank accounts',
      description: 'Get a complete view of your income and spending. Receive alerts for upcoming payments and find opportunities to save money - a simple <br/> and convenient way to manage your finances in one place',
      img: 'cash-managment',
      buttonText: 'Manage cash and accounts',
      isOpen: false,
      landingName: 'LandingCashManagementPage',
    },
    {
      title: 'Credit score management',
      description: 'Receive and monitor the credit score of your business for free. <br/> Use the unique opportunity to boost it in just 3 simple steps',
      img: 'credit-score-managment',
      buttonText: 'Get free credit score',
      isOpen: false,
      landingName: 'LandingCreditScorePage',

    },
    {
      title: 'Business valuation',
      description: 'Calculate the value of your business by using a business valuation <br/> simulator and compare results with similar public companies for a <br/> competitive perspective',
      img: 'business-valuation',
      buttonText: 'Calculate business valuation',
      isOpen: false,
      landingName: 'LandingValuationPage',
    },
    // {
    //   title: 'SMB funding',
    //   description: "Make financing accessible, fast, and easy. Get a business financing <br/> offers that won't affect your business credit score",
    //   img: 'smb-funding',
    //   buttonText: 'Get funding',
    //   isOpen: false,
    //   landingName: 'LandingLoanPage',
    // },
    {
      title: 'Carbon Footprint',
      description: 'Assess a business’s environmental and social impact with our ESG <br/> component. It evaluates spending patterns and calculates CO2e, <br/> allowing owners to make more environmentally conscious decisions',
      img: 'carbon-footptint',
      buttonText: 'Check my carbon footprint',
      isOpen: false,
      landingName: 'LandingCarbonPage',

    },
    {
      title: 'Dashboard Pro',
      description: 'Analyze business health by connecting third-party apps and receive actionable insights, performance alerts, and intelligent forecasting',
      img: 'business-360',
      buttonText: 'Get overview',
      isOpen: false,
      landingName: 'Landing360Page',

    },
  ]

  changeSlide(index: number): void {
    this.currentSlide = index;
  }

  openAccordion(index: number): void {
    this.cards[index].isOpen = !this.cards[index].isOpen;
  }

  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
